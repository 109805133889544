<template>
  <div class="right">
    <div class="banner">
      <div class="text">
        {{ route.meta.title ?? "无标题" }}
      </div>
      <div class="info">
        <!-- 通知 -->
        <div class="icon-inner" v-if="false">
          <font-awesome-icon :icon="['far', 'bell']" class="icon" />
        </div>
        <!-- 用户信息 -->
        <div class="userinfo">
          <el-dropdown trigger="hover" size="large" class="dropdown">
            <div class="real-info" style="display: flex;">
              <img :src="userProfile.getInfo().avatar" alt="" class="img">
              <div class="show-info">
                <div class="username">
                  <span class="name" style="display: block;">
                    {{ userProfile.getInfo().nickname }}
                  </span>
                  <span class="user" style="display: block;">
                    {{ userProfile.getInfo().username }}
                  </span>
                </div>
                <font-awesome-icon :icon="['fas', 'chevron-down']" class="icon" />
              </div>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="dropdown-menu">
                <el-dropdown-item class="item" @click="handleToLink">
                  <font-awesome-icon :icon="['fas', 'user']" class="icon" />
                  个人信息
                </el-dropdown-item>
                <el-dropdown-item class="item" @click="handleLogout">
                  <font-awesome-icon :icon="['fas', 'right-to-bracket']" class="icon" />
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import api from './api'
import useLoginStore from "@/stores/login/login"

import useUserProfileStore from '@/stores/userProfile'

const loginStore = useLoginStore()
const userProfile = useUserProfileStore()

const route = useRoute()

const handleToLink = () => {
  navigateTo('/userCenter/personalConfig')
}

onMounted(async () => {
  try {
    const res: any = await api.getUserMessage()
    userProfile.setInfo(res.data)
  } catch (err) {
    console.log(err)
  }
})

// 退出登录
const handleLogout = () => {
  loginStore.logout()
}
</script>

<style lang="less" scoped>
.right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .banner {
    height: 54px;
    width: 100%;
    background-color: var(--third-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #404040;

    .text {
      font-size: 22px;
      margin-left: 40px;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 4px;
        height: 16px;
        background-color: aqua;
        border-radius: 2px;
        margin-right: 10px;
      }
    }

    .info {
      display: flex;
      align-items: center;

      .icon-inner {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 14px;
          color: black;
        }
      }

      .userinfo {
        margin-right: 40px;
        cursor: pointer;

        .dropdown {
          border: none;
          box-shadow: none;
        }

        :deep(.real-info) {
          box-shadow: none;

          .img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .show-info {
            display: flex;
            align-items: center;

            .username {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .name {
                font-size: 14px;
                color: #fff;
              }

              .user {
                margin-top: 4px;
                font-size: 12px;
                color: #918e8e;
              }
            }

            .icon {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .content {
    flex: 1;
    padding: 17px 12px 26px 12px;
    background-color: var(--bg-color);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    /*定义滚动条轨道：内阴影+圆角*/
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    /*定义滑块：内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }
  }
}

.dropdown-menu {
  background-color: var(--nav-color);

  ::v-deep .item {
    color: var(--text-color);
    background-color: var(--nav-color);

    &:hover {
      background-color: #2f323a;
    }

    .icon {
      margin-right: 10px;
    }
  }

  ::v-deep .el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: #0d1117;
    color: #8b8d97;
  }
}
</style>
